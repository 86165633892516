import { template as template_0e6639bb96ec45e5b75d93ad81919b82 } from "@ember/template-compiler";
const WelcomeHeader = template_0e6639bb96ec45e5b75d93ad81919b82(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
