import { template as template_3d35bffcdcbf4974a3691eb681f0d606 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3d35bffcdcbf4974a3691eb681f0d606(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
