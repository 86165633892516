import { template as template_0329f561ab2b458195fa0770d2ac9f98 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_0329f561ab2b458195fa0770d2ac9f98(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
