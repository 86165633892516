import { template as template_2b23f65180bd4bb18dcd48cf5c80b9f3 } from "@ember/template-compiler";
const FKLabel = template_2b23f65180bd4bb18dcd48cf5c80b9f3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
